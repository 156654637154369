<template>
    <CRow>
      <CCol col="12" xl="12">
        <CCard>
          <CCardHeader>
            <CRow>
                <CCol md="7">
                      <h4>Preset Rules</h4>
                </CCol>
                <CCol md="5">
                     <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />
                </CCol>
            </CRow>
          </CCardHeader><br/>
          <CCardBody>
            <CRow>
              <CCol col="12" class="text-left">
                 <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol>
            </CRow>
            <div class="table-responsive table">
              <b-table striped hover :items="items" :fields="fields" 
                  :current-page="currentPage" 
                  :per-page="perPage" 
                  :filter="filter" 
                  sort-by="ruleCode" 
                  :sort-asc="true">

                <template v-slot:cell(createDate)="{ item }">
                    {{ item.createDate | dateWithTime }}
                </template>

              </b-table>
            </div>

            <div>
              <b-row>
                <b-col sm="3">
                   <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
                </b-col>
                <b-col sm="9">
                   <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
                   </b-form-select>
                </b-col>
              </b-row>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </template>

  <script>
  import FraudService from '@/api/FraudService.js';

   export default {
      name: "presetrules",
      data() {
        return {
          spinner:false,
          items:[],
          rules:[],
          params:[],
          fields: [
            {
              key: 'ruleCode',
              label : 'Code',
              sortable: true
            },
            {
              key: 'name',
              label : 'Name',
              sortable: true
            },
            {
              key: 'description',
              label : 'Description',
              sortable: true
            },
            {
              key: 'fields',
              label : 'Parameters',
              sortable: true
            },
            {
              key: 'createDate',
              label : 'Created Date',
              sortable: true
            }
          ],
          currentPage: 1,
          perPage: 10,
          filter: null,
          seen:false,
          msg :'',
          color:''
        };
      },
      filters: {
          upper(value){
            if(!value) return ''
            value = value.toString()
            return value.toUpperCase()
          },
          lower(value){
             if(!value) return ''
             value = value.toString()
             return value.toLowerCase()
          },

          dateSubstr(value){
             if(!value) return ''
             value = value.toString()
             return value.substring(0,10)
          },

          IdSubstr(value){
             if(!value) return ''
             value = value.toString()
             return value.substring(0,5) + '...'
          },
          dateWithTime(value){
             if(!value) return ''
             var temp = value.toString().split(".");
             value = temp[0].replace("T"," ");
             return value;
          },

      },
      created () {
         this.getAllRule();
      },
      methods: {

        getFieldByRule(ruleCode){
          let fields = [];
          switch(ruleCode) {
            case "RULE0001":
                //sameSenderGapSeconds
                fields.push('Transaction Velocity Break Interval by a User');
              break;
              case "RULE0002":
                //sameSenderReceiverGapSeconds
                fields.push('Beneficiary Velocity Break Interval by a User');
              break;
              case "RULE0003":
                //singleTransactionAmount
                fields.push('Max. Single Transaction Amount Per User');
              break;
              case "RULE0004":
                //prevDayTotalExceedPercentage, totalTransSafetyValue
                fields.push('Current vs Previous Day Value Excess Permitted (in %)');
                fields.push('Current Day Max Safety Value');
              break;
              case "RULE0006" :
                fields.push('NRIC List');
                fields.push('UEN List');
                fields.push('Email List');
                fields.push('Receiver Name List');
                fields.push('Bank Name List');
                fields.push('Mobile Number List');
                fields.push('Account Number List');
              break;
              default:
                //globalTransSafetyValue
                fields.push('Global Trans Safety Value');
          }
          return fields;
        },

        getAllRule: function() {
          FraudService.getAllRule().then(resp => {
             for (var key in resp) {
                var row = resp[key];
                resp[key]['fields'] = this.getFieldByRule(row.ruleCode);
              }              
              this.items = resp;
          }, error => {
            this.spinner = false;
          });
        }
      }
   };

  </script>
  <style>
      .table{ overflow-y:scroll; }
  </style>